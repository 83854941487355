(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/module/assets/javascripts/helpers/load-styles.js') >= 0) return;  svs.modules.push('/components/core/module/assets/javascripts/helpers/load-styles.js');
"use strict";


const {
  helpers
} = svs.core.module;
function loadStyles(styles) {
  const $head = $(document.getElementsByTagName('head')[0]);
  const dynamicCSSclass = 'dynamic-css';
  let link;
  let linkhref;
  const linksToAdd = [];
  const currentStylesheets = $('link[rel="stylesheet"]').map(function () {
    return $(this).attr('href');
  });
  if (!styles) {
    return helpers.logger.debug('No provided stylesheets to add.');
  }
  $.each(styles, (index, style) => {
    linkhref = svs.core.config.data.svsconfig.cdn + style;
    if ($.inArray(linkhref, currentStylesheets) === -1) {

      helpers.logger.debug("Adding ".concat(linkhref));
      link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = linkhref;
      link.className = dynamicCSSclass;
      linksToAdd.push(link);
      currentStylesheets.push(linkhref);
    }
  });
  function isIE11orEdge() {
    return navigator.userAgent.indexOf('rv:11') >= 0 || navigator.userAgent.indexOf('Edge') >= 0;
  }
  function getStyleElementImport(href) {
    const styleElement = document.createElement('style');
    styleElement.className = dynamicCSSclass;
    styleElement.appendChild(document.createTextNode("@import url(".concat(href, ")")));
    return styleElement;
  }
  $.each(linksToAdd, (index, link) => {
    $head.prepend(isIE11orEdge() ? getStyleElementImport(link.href) : link); 
  });
}
svs.core.module.helpers.loadStyles = loadStyles;

 })(window);