(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/module/assets/javascripts/filter.js') >= 0) return;  svs.modules.push('/components/core/module/assets/javascripts/filter.js');
"use strict";


const {
  helpers
} = svs.core.module;
const loadedAssetsForRoutes = {};

function setAssetsLoadedForRoute(routeUrl) {
  loadedAssetsForRoutes[routeUrl] = true;
}
function isRouteLoaded(routeUrl) {
  if (loadedAssetsForRoutes[routeUrl]) {
    return true;
  }
  return false;
}
function getAssets(routeUrl, arg, callback) {
  let url;
  callback = callback || function () {};
  if (loadedAssetsForRoutes[routeUrl]) {
    helpers.logger.debug('Assets already loaded.', routeUrl);
    callback();
  } else {
    if (routeUrl.indexOf('{') > 0 && routeUrl.indexOf('}') > 0) {
      url = routeUrl.replace(/{.*}/, arg);
    } else {
      url = routeUrl;
    }
    helpers.logger.debug('Fetching assets.', url);
    svs.core.module.load(url, null, () => {
      loadedAssetsForRoutes[routeUrl] = true;
      callback();
    });
  }
  return svs.core.module;
}
svs.core.module.filter = {
  isRouteLoaded,
  setAssetsLoadedForRoute,
  getAssets
};

 })(window);