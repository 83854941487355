(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/module/assets/javascripts/helpers/cache.js') >= 0) return;  svs.modules.push('/components/core/module/assets/javascripts/helpers/cache.js');
"use strict";


const {
  logger
} = svs.core.module.helpers;
let cache = {};

function storeInCache(key, data) {
  const cacheData = {};
  cacheData.init = data.init;
  cacheData.html = data.html;
  cache[key] = cacheData;
}

function getFromCache(key) {
  if (Object.prototype.hasOwnProperty.call(cache, key)) {
    return cache[key];
  }
  return false;
}

function clearCacheByKey(key) {
  if (Object.prototype.hasOwnProperty.call(cache, key)) {
    delete cache[key];
    logger.debug("".concat(key, " was removed from cache"));
  }
}

function clearCache() {
  logger.debug('Cache cleared');
  cache = {};
}
svs.core.userSession.on('afterLogout', clearCache);
svs.core.module.helpers.cache = {
  clearCache,
  clearCacheByKey,
  getFromCache,
  storeInCache
};

 })(window);