(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/module/assets/javascripts/helpers/load-scripts.js') >= 0) return;  svs.modules.push('/components/core/module/assets/javascripts/helpers/load-scripts.js');
"use strict";


const {
  helpers
} = svs.core.module;
function addScript(file, callback, container) {
  const scriptsrc = svs.core.config.data.svsconfig.cdn + file;
  const scriptElem = document.querySelector("script[src=\"".concat(scriptsrc, "\"]"));
  if (scriptElem) {
    if (!scriptElem.classList.contains('dynamic-js') || scriptElem.classList.contains('loaded')) {
      callback();
    } else {
      scriptElem.addEventListener('load', callback);
    }
    return;
  }

  const script = document.createElement('script');
  script.defer = true;
  script.async = false;
  script.type = 'text/javascript';
  script.className = 'dynamic-js';
  let isCallbackFired = false;
  script.addEventListener('error', () => {
    callback();
    helpers.logger.error("Failed to load script: ".concat(scriptsrc));
  });
  const onload = () => {
    if (!isCallbackFired) {
      isCallbackFired = true;
      script.classList.add('loaded');
      callback();
    }
  };

  script.addEventListener('load', onload);

  script.onreadystatechange = function () {
    if (this.readyState === 'complete') {
      onload();
    }
  };
  script.src = scriptsrc;
  helpers.logger.debug("Adding ".concat(file));
  container.appendChild(script);
}
function loadScripts(scripts, callback) {
  if (!scripts || scripts.length === 0) {
    if (callback) {
      callback();
    }
    return;
  }
  const container = document.createDocumentFragment();
  let loadedScripts = 0;
  const handleLoadedScript = () => {
    loadedScripts += 1;
    if (loadedScripts === scripts.length) {
      callback();
    }
  };
  scripts.forEach(script => {
    addScript(script, handleLoadedScript, container);
  });
  document.head.appendChild(container);
}
svs.core.module.helpers.loadScripts = loadScripts;

 })(window);