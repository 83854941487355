(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/module/assets/javascripts/load.js') >= 0) return;  svs.modules.push('/components/core/module/assets/javascripts/load.js');
"use strict";


const {
  module: _module
} = svs.core;
const {
  helpers
} = svs.core.module;
const loadAndCache = (url, callback, silentLoad, invalidateCache) => {
  if (invalidateCache) {
    helpers.cache.clearCacheByKey(url);
  }
  const cacheData = helpers.cache.getFromCache(url);
  if (cacheData) {
    helpers.logger.debug("".concat(url, " found in module cache"));
    if (cacheData.init) {
      eval(cacheData.init);
    }
    if (callback) {
      callback(null, cacheData);
    }
  } else {
    helpers.logger.debug("".concat(url, " NOT found in module cache, load from server"));
    $.getJSON(url, data => {
      helpers.loadStyles(data.styles);
      if (data.data) {
        $.extend(true, svs, data.data);
      }
      helpers.loadScripts(data.scripts, () => {
        if (!silentLoad && data.init) {
          eval(data.init);
        }
        helpers.cache.storeInCache(url, data);
        if (callback) {
          callback(null, data);
        }
      });
    }).fail(callback);
  }
};

_module.load = function (url, htmlFn, callback) {
  let urlArg = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let silentLoad = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  let invalidateCache = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  if (urlArg) {
    return _module.filter.getAssets(url, urlArg, callback);
  }
  if (url.indexOf('/cl/') === 0) {
    url = "/_".concat(svs.core.config.data.svsconfig.tribe).concat(url);
  }
  if (url.indexOf('json=true') < 0) {
    url += '?json=true';
  }
  url += '&v=2';
  loadAndCache(url, callback, silentLoad, invalidateCache);
};

_module.loadModuleComponent = function (component, callback) {
  let silentLoad = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let invalidCache = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const typeofCallback = typeof callback;
  if (typeofCallback !== 'function') {
    throw new Error("Expected param `callback` to be a function. Received: `".concat(typeofCallback, "`"));
  }
  const url = "/_".concat(svs.core.config.data.svsconfig.tribe, "/mcl/").concat(svs.core.data.module, "/").concat(component);
  loadAndCache(url, callback, silentLoad, invalidCache);
};

 })(window);